import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import SlidingPane from "react-sliding-pane";
import { withTranslation } from "react-i18next";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { isMobile } from "react-device-detect";
import moment from "moment";

import Categories from "./categories/index";
import TabBar from "./../tabBar/tabBar";
import Info from "../info/index";
import ShopNotFound from "../infoScreens/shopNotFound/index";
import { Card, LeftArrow, RightArrow } from "./horizontalScroll/horizontalScroll";
import NoPromotions from "../infoScreens/noPromotions/index";
import TopOffers from "./top_offers/index";
import AllCategories from "./../categories/categories";
import NoCatalogSelected from "../infoScreens/noCatalogSelected/index";
import ExpiredShop from "../infoScreens/expiredShop/index";

import {
    fetchProductsCustom
} from "../../actions/products";
import { fetchShop } from "./../../actions/shop/index";
import { addRemoveItem } from "../../actions/cart/index";
import { notificationSubscribe, NotificatinCheckoutTokens, subscribeToTopic, unsubscribeFromTopic, subscribeToCheckout } from "../../apis/apiData";

import history from "./../history";
import { setLanguage } from "./../../globalFunctions";

import logo_image from "./assets/img/logo_image.svg";

import "react-sliding-pane/dist/react-sliding-pane.css";
import "./assets/css/catalog.css";

class Catalog extends React.Component {
    renderComponent = false;
    constructor() {
        super();
        this.state = {
            toggleInfoModal: false,
            shopInfoModal: false,
            top_offers_page_number: 1
        };
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("id") && searchParams.has("productId")) {
            const el = document.querySelector(".catalogContainer");
            el.classList.add("whiteBg");
            const shopId = searchParams.get("id");
            this.props.fetchShop(shopId)
                .then(async (shop) => {
                    const language = sessionStorage.getItem("lng") || shop?.lng || "en";
                    setLanguage(language);
                    moment.locale(language);
                    if ("caches" in window) {
                        caches.open("cache_store").then((cache) => {
                            cache.put("current_store", new Response(JSON.stringify(shopId)));
                        });
                    }
                })
                .then(() => {
                    const productId = searchParams.get("productId");
                    switch (productId) {
                        case "1":
                            history.push('/category/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI5MjA5NTM5Mzk2Ng==?from=home&show-popup=1');
                            break;
                        case "2":
                            history.push('/category/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI5MjA5NTM2MTE5OA==?from=home&show-popup=2');
                            break;
                        case "3":
                            history.push('/category/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI5MjA5NDM3ODE1OA==?from=home&show-popup=3');
                            break;
                        case "4":
                            history.push('/category/Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI5MjA5NTQyNjczNA==?from=home&show-popup=4');
                            break;
                        default:
                            break;
                    }
                });
        } else if (searchParams.has("id") && searchParams.has("cart")) {
            const oldShopId = this.props.shop?.data?.id;
            const shopId = searchParams.get("id");
            const cartId = searchParams.get("cart");
            await this.props.fetchShop(shopId);
            const language = sessionStorage.getItem("lng") || "en";
            setLanguage(language);
            moment.locale(language);
            let sessionShopId = sessionStorage.getItem(shopId);
            subscribeToCheckout(this.props.shop?.data, cartId);
            if (sessionShopId) {
                sessionShopId = JSON.parse(sessionShopId);
            }
            if (!sessionShopId || (sessionShopId && sessionShopId.id.toString() !== cartId.toString())) {
                NotificatinCheckoutTokens(cartId, this.props.shop, true, true);
            }
            sessionStorage.removeItem("checkout_tokens_checked");
            localStorage.removeItem("notification_tokens");
            if ("caches" in window) {
                caches.open("cache_store").then((cache) => {
                    cache.put("current_store", new Response(JSON.stringify(this.props.shop?.data?.id)));
                });
            }
            if (localStorage.getItem("messagingToken")) {
                const token = JSON.parse(localStorage.getItem("messagingToken"));
                notificationSubscribe(token);
                if (!oldShopId || (oldShopId.toString() !== shopId.toString())) {
                    if (oldShopId) {
                        await unsubscribeFromTopic(token, oldShopId);
                    }
                    await subscribeToTopic(token, shopId);
                }
            }
            if (!isMobile) {
                history.push('/home');
            } else {
                history.push(`/cart?cart-id=${cartId}`);
            }
        } else {
            this.renderComponent = true;
            let shopId = this.props.shopId;
            if (!shopId) {
                shopId = this.props.shop.data.id;
            }
            this.props.checkLoadingCatalog(shopId);
            window.scrollTo(0, 0);
        }
    }

    renderCategories() {
        const SkeletonData = [1, 2, 3, 4, 5, 6].map((i) => {
            return {
                id: i,
                skeleton: true,
                products: [i]
            }
        }),
            { collections, shop } = this.props;
        const categories = collections.LOADING ? SkeletonData : collections?.collections_data?.collections || [];
        if (categories.length > 0) {
            return categories.map((coll, index) => {
                return (
                    <Categories
                        key={index}
                        collection={coll}
                        catalogVendor={this.props.vendor}
                        loading={coll.skeleton || false}
                        fromPage="home"
                        shopId={shop.data.id}
                    />
                );
            });
        } else {
            return null
        }
    }

    renderTopOffers(shop_custom_theme) {
        const { data, LOADING } = this.props.customProducts,
            SkeletonLength = (data.products.length > 0) ? data.products : [1, 2, 3, 4, 5],
            SkeletonData = SkeletonLength.map((p, i) => {
                return {
                    id: i,
                    skeleton: true
                }
            }),
            topProducts = LOADING ? SkeletonData : data.products || [],
            { cart, customProducts, addRemoveItem } = this.props;
        const newItemsLimit = topProducts.length;

        return (
            <ScrollMenu
                LeftArrow={LeftArrow}
                RightArrow={
                    <RightArrow limit={newItemsLimit} pushNewItems={this.fetchMoreTopOffers} />
                }
            >
                {topProducts.map((prod) => {
                    const isOnTheCart =
                        !LOADING && cart.lineItems
                            ? (cart.lineItems.length > 0)
                                ? cart.lineItems.find((p) => p.variant?.id === prod.variants[0]?.id)
                                : false
                            : false;
                    return (
                        <Card
                            key={prod.id}
                            itemId={prod.id}
                            child={
                                <TopOffers
                                    product={prod}
                                    loading={prod.skeleton || false}
                                    category={
                                        !prod.skeleton
                                            ? customProducts?.id ||
                                            prod?.category ||
                                            prod?.variants[0]?.product?.collections[0] ||
                                            null
                                            : null
                                    }
                                    isOnTheCart={isOnTheCart}
                                    addRemoveItem={addRemoveItem}
                                    shop_custom_theme={shop_custom_theme}
                                />
                            }
                        />
                    );
                })}
            </ScrollMenu>
        );
    }

    showAboutModal = (show, shop_info = false) => {
        this.setState({
            toggleInfoModal: show,
            shopInfoModal: shop_info
        }, () => {
            const body = document.querySelector("body");
            if (this.state.toggleInfoModal) {
                body.style.overflow = "hidden";
            } else {
                body.style.removeProperty("overflow");
            }
        });
    }

    fetchMoreTopOffers = () => {
        const { shop, customProducts } = this.props;
        if (customProducts.data.total_pages === 0) {
            return false;
        } else {
            if (!customProducts.LOADING && (customProducts.data.total_pages !== 0)) {
                this.setState((state) => ({
                    top_offers_page_number: (shop.data.store_type === "Woocommerce") ? state.top_offers_page_number + 1 : customProducts.data.total_pages
                }), () => {
                    this.props.fetchProductsCustom(this.state.top_offers_page_number);
                });
            }
        }

    };

    render() {
        if (this.renderComponent) {
            const { collections, shop, customProducts, t } = this.props,
                { image, shop_color, custom_theme, discount_price_color, reqular_price_color, id } = shop.data,
                shop_custom_theme = { shop_color, custom_theme, discount_price_color, reqular_price_color, shop_id: id },
                { shopInfoModal } = this.state;
            return (
                <div className="catalogContainer">
                    <div
                        className={`catalogHeader ${custom_theme ? "custom_theme" : ""} ${shop_color ? "custom_bg" : ""}`}
                    >
                        <div className="background" style={shop_color ? { backgroundColor: shop_color } : {}}></div>
                        <div
                            className="logoContainer"
                            style={(custom_theme && shop_color) ? { background: shop_color } : {}}
                            onClick={() => {
                                if (image) {
                                    this.showAboutModal(true, true);
                                }
                            }}
                        >
                            <img
                                src={(custom_theme && image) ? image : logo_image}
                                alt="logo"
                                className={(custom_theme && !image) ? "default_image" : ""}
                            />
                        </div>
                    </div>
                    {!custom_theme && (
                        <div
                            className="shop_title"
                        >
                            {shop?.data?.name || ""}
                        </div>
                    )}
                    {((id && id !== 74) || !id) && (
                        <div className={`infoIconWrapper ${custom_theme ? "custom_theme" : ""}`}>
                            <div onClick={() => this.showAboutModal(true)}>
                                <div
                                    className="info_icon"
                                    style={shop_color ? { background: shop_color } : {}}
                                ></div>
                            </div>
                        </div>
                    )}
                    {!id ? (
                        <div className="shop_error_container">
                            <NoCatalogSelected />
                        </div>
                    ) : shop.ERROR ? (
                        <div className="shop_error_container">
                            <ShopNotFound />
                        </div>
                    ) : (shop?.data?.catalogue_limit_expired === true) ? (
                        <div className="shop_error_container">
                            <ExpiredShop />
                        </div>
                    ) : (!collections.LOADING && !customProducts.LOADING
                        && (collections?.collections_data?.collections?.length === 0)
                        && (customProducts?.data?.products?.length === 0)
                    ) ? (
                        <NoPromotions custom_theme={custom_theme} shop_color={shop_color} />
                    ) : (
                        <div className="top_offers_categories_wrapper">
                            {(customProducts.LOADING || (!customProducts.LOADING && (customProducts?.data?.products?.length > 0))) && (
                                <div className="topOffersContainer">
                                    {this.renderTopOffers(shop_custom_theme)}
                                </div>
                            )}
                            {(collections?.collections_data?.collections?.length > 0) && (
                                <div
                                    className="categoriesContainer"
                                    style={(customProducts?.data?.products?.length === 0)
                                        ? { marginTop: "30px" }
                                        : (!customProducts.LOADING && (customProducts?.data?.products?.length === 0))
                                            ? { paddingTop: "20px" }
                                            : {}
                                    }
                                >
                                    <div className="title_container">
                                        <h2 className="categoriesTitle">{t("shop_by_category")}</h2>
                                    </div>
                                    <div className={`categoriesWrapper ${(shop?.data?.category && (shop.data.category.id === 3)) ? "all_categories" : ""}`}>
                                        {(shop?.data?.category && (shop.data.category.id === 3))
                                            ? (
                                                <AllCategories
                                                    is_component={true}
                                                />
                                            )
                                            : (this.renderCategories())
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <TabBar activeTab="home" />
                    <SlidingPane
                        closeIcon={
                            <div
                                onClick={() => this.showAboutModal(false)}
                                style={shopInfoModal ? (shop?.data?.shop_color) ? { color: shop.data.shop_color } : {} : {}}
                            >
                                {t("btn_cancel")}
                            </div>
                        }
                        isOpen={this.state.toggleInfoModal}
                        from="bottom"
                        onRequestClose={() => this.showAboutModal(false)}
                        className="info_modal_wrapper"
                        shouldCloseOnEsc={false}
                    >
                        <Info shopInfoModal={shopInfoModal} />
                    </SlidingPane>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        collections: state.collections,
        customProducts: state.customProducts,
        vendor: state.vendor.data,
        shop: state.shop,
        cart: state.cart,
        checkout: state.checkout.data
    };
};

export default connect(mapStateToProps, {
    fetchProductsCustom,
    fetchShop,
    addRemoveItem
})(withRouter(withTranslation()(Catalog)));