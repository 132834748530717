import React from "react";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/categories.css";
import analyticsEvents from "../../../analyticsEvents";

import no_image from "../../images/no-image.png";

class Categories extends React.Component {

    render() {
        const { collection, loading, shopId } = this.props;
        const fromHome = new URLSearchParams(window.location.search).has('from');
        return (
            <div className="categoryBox" key={collection.id}>
                <Link
                    className="productLink"
                    to={`/category/${collection.id}${fromHome ? "?from=home" : ""}`}
                    style={loading ? { pointerEvents: "none" } : null}
                    onClick={() => {
                        analyticsEvents.categoryOpen(shopId, collection.id);
                    }}
                >
                    <div className="categoryTitle">
                        {loading
                            ? (<Skeleton containerClassName="title_skeleton" />)
                            : (
                                <TextTruncate
                                    line={2}
                                    truncateText="..."
                                    text={collection.title}
                                />
                            )
                        }
                    </div>
                    <div className="categoryImg">
                        {loading
                            ? (
                                <Skeleton
                                    containerClassName="image_skeleton"
                                />
                            )
                            : (
                                <img
                                    className="catalogCategoryImg"
                                    src={collection.image ? (collection.image.src) : no_image}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = no_image;
                                    }}
                                    alt="category_image"
                                />
                            )
                        }
                    </div>
                </Link>
            </div>
        );
    }
}

export default Categories;