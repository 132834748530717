import shopifyClient from "../../apis/shopifyApi";
import WoocommerceApi from "../../apis/woocommerceApi";

const extractCurrency = (s) => {
    let span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent.replace(/[0-9].+/g, " ") || span.innerText.replace(/[0-9].+/g, " ");
};

const regularPrice = (regular_price, price) => {
    let regilarPrice = price;
    if ((regular_price !== "") && (price !== "") && (parseInt(regular_price) !== parseInt(price))) {
        regilarPrice = regular_price;
    }
    return parseInt(regilarPrice);
}

// Fetch product variations
const fetchProductVariations = (productId) => {
    return async function (dispatch, getState) {

        const shop = getState().shop;

        const variations = {
            variations: [],
            on_sale: [],
            compare_variations: null
        };

        dispatch({
            type: "FETCHING_PRODUCTS_VARIATIONS",
            payload: true
        });

        if (shop.data.store_type === "Woocommerce") {
            await WoocommerceApi(shop.data).get(`products/${productId}/variations`).then(async (res) => {
                if (res.data) {

                    console.log(res.data);

                    variations.on_sale = res.data.filter((v) => v.on_sale);
                    await res.data.forEach((variation) => {
                        const options = {};

                        if (variation.attributes && (variation.attributes.length > 0)) {
                            variation.attributes.forEach((attr) => {
                                options[attr.name.toLowerCase()] = attr.option;
                                if (!variations.compare_variations || !variations.compare_variations[attr.name.toLowerCase()]) {
                                    variations.compare_variations = {
                                        ...variations.compare_variations, [attr.name.toLowerCase()]: [attr.option]
                                    };
                                } else if (!variations.compare_variations[attr.name.toLowerCase()].includes(attr.option)) {
                                    variations.compare_variations[attr.name.toLowerCase()] = [...variations.compare_variations[attr.name.toLowerCase()], attr.option];
                                }
                            });
                        }

                        variations.variations.push({
                            id: variation.id,
                            options,
                            price: variation.price,
                            sale_price: variation.sale_price,
                            on_sale: variation.on_sale
                        });
                    });

                    dispatch({
                        type: "FETCH_PRODUCTS_VARIATIONS",
                        payload: variations
                    });

                }
            }).catch(() => {
                dispatch({
                    type: "FETCH_PRODUCTS_VARIATIONS",
                    payload: variations
                });
            });
        }

        dispatch({
            type: "FETCHING_PRODUCTS_VARIATIONS",
            payload: false
        });

    }
}

//Fetch - products
export const fetchProducts = () => {
    return async function (dispatch, getState) {

        const shop = getState().shop;

        if (shop.data.store_type === "Woocommerce") {
            WoocommerceApi(shop.data).get("products").then((res) => {
                dispatch({
                    type: "FETCH_PRODUCTS",
                    payload: res
                });
            });
        } else {
            const shopifyApi = shopifyClient(shop.data);

            await shopifyApi.product.fetchAll().then((res) => {
                dispatch({
                    type: "FETCH_PRODUCTS",
                    payload: res
                });
            });
        }
    }
};

//Fetch - fetch products custom
export const fetchProductsCustom = (page_number = 0) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            products_per_page = 10;
        let customProducts = getState().customProducts.data,
            custom_products = {
                total_pages: 0,
                products: []
            };

        dispatch({
            type: "FETCHING_CUSTOM_PRODUCTS",
            payload: true
        });

        switch (page_number) {
            case 0:
                page_number = (shop.data.store_type === "Woocommerce") ? 1 : 0;
                customProducts = custom_products
                break;
            case false:
                dispatch({
                    type: "FETCH_CUSTOM_PRODUCTS",
                    payload: custom_products
                });
                break;
            default:
                break;
        }

        if ((page_number !== false) && !shop.ERROR) {

            if (shop.data.store_type === "Woocommerce") {
                await WoocommerceApi(shop.data).get("products", {
                    per_page: products_per_page,
                    page: page_number,
                    featured: true,
                    orderby: "popularity"
                }).then(async (res) => {
                    const products = await res.data.map((product) => {
                        const price = (product.sale_price !== "") ? product.sale_price : (product.price !== "") ? product.price : 0;
                        const categoriesMinId = Math.min(...product.categories.map(({ id }) => id));
                        const categories = product.categories.filter(({ id }) => id === categoriesMinId);
                        return {
                            id: product.id,
                            title: product.name,
                            description: product.description,
                            meta_data: product.meta_data || null,
                            date_on_sale_to_gmt: product.date_on_sale_to_gmt || null,
                            date_on_sale_from_gmt: product.date_on_sale_from_gmt || null,
                            images: [{
                                src: product?.images[0]?.src || null
                            }],
                            variants: [{
                                id: product.id,
                                compareAtPrice: regularPrice(product.regular_price, price),
                                price: parseInt(price),
                                priceV2: {
                                    currencyCode: extractCurrency(product.price_html)
                                },
                                product: {
                                    collections: [{ id: categories[0].id, title: categories[0].name }]
                                }
                            }],
                        }
                    });

                    custom_products = {
                        total_pages: res.headers['x-wp-totalpages'] > page_number ? res.headers['x-wp-totalpages'] : 0,
                        products: [...customProducts.products, ...products]
                    };

                    dispatch({
                        type: "FETCH_CUSTOM_PRODUCTS",
                        payload: custom_products
                    });

                }).catch(() => {
                    dispatch({
                        type: "FETCH_CUSTOM_PRODUCTS",
                        payload: custom_products
                    });
                });
            } else {
                const shopifyApi = shopifyClient(shop.data);

                const args = {
                    first: products_per_page,
                    reverse: false,
                    "query": "tag:important"
                };

                if (page_number !== 0) {
                    args.after = customProducts.total_pages;
                }

                const productQuery = shopifyApi.graphQLClient.query((root) => {
                    root.addConnection('products', { args }, (product) => {
                        product.add("id");
                        product.add("title");
                        product.add("description");
                        product.addConnection("images", { args: { first: 5 } }, (image) => {
                            image.add('src');
                        });
                        product.addConnection("variants", { args: { first: 5 } }, (variant) => {
                            variant.add("compareAtPrice");
                            variant.add("price");
                            variant.add("priceV2", (price) => {
                                price.add("currencyCode");
                            });
                            variant.add("product", (product) => {
                                product.add("handle");
                                product.add("id");
                                product.addConnection("collections", { args: { first: 5 } }, (collection) => {
                                    collection.add("title");
                                    collection.add("id");
                                    collection.add("handle");
                                });
                            });
                        });
                    });
                });

                await shopifyApi.graphQLClient.send(productQuery).then((model, data) => {
                    if (model.errors || model.model.products.length === 0) {
                        const collectionQuery = shopifyApi.graphQLClient.query((collRoot) => {
                            collRoot.addConnection('collections', { args }, (collection) => {
                                collection.addConnection("products", { args: { first: 5, reverse: true } }, (product) => {
                                    product.add("id");
                                    product.add("title");
                                    product.add("description");
                                    product.addConnection("images", { args: { first: 5 } }, (image) => {
                                        image.add("src");
                                    });
                                    product.addConnection("variants", { args: { first: 5 } }, (variant) => {
                                        variant.add("compareAtPrice");
                                        variant.add("price");
                                        variant.add("priceV2", (price) => {
                                            price.add("currencyCode");
                                        });
                                    });
                                });
                            });
                        });

                        shopifyApi.graphQLClient.send(collectionQuery).then((collModel, collData) => {
                            if (collModel.model) {
                                if (collModel.model.collections[0]) {

                                    const { edges, pageInfo } = collModel.data.collections;
                                    custom_products = {
                                        total_pages: pageInfo.hasNextPage ? edges[edges.length - 1].cursor : 0,
                                        products: [...customProducts.products, ...collModel.model.collections[0].products]
                                    };

                                    dispatch({
                                        type: "FETCH_CUSTOM_PRODUCTS",
                                        payload: custom_products
                                    });

                                }
                            }
                        });
                    } else {

                        const { edges, pageInfo } = model.data.products;
                        custom_products = {
                            total_pages: pageInfo.hasNextPage ? edges[edges.length - 1].cursor : 0,
                            products: [...customProducts.products, ...model.model.products]
                        };

                        dispatch({
                            type: "FETCH_CUSTOM_PRODUCTS",
                            payload: custom_products
                        });

                    }
                }).catch(() => {
                    dispatch({
                        type: "FETCH_CUSTOM_PRODUCTS",
                        payload: custom_products
                    });
                });
            }

        }

        dispatch({
            type: "FETCHING_CUSTOM_PRODUCTS",
            payload: false
        });

    }
};

//Fetch - products by collection
export const fetchProductsByCollection = (collection_id, page_number = 0, per_page = 10) => {
    return async function (dispatch, getState) {

        const shop = getState().shop;

        let products = getState().products,
            defaultProducts = {
                category_title: "",
                total_pages: 0,
                products: []
            };

        switch (page_number) {
            case 0:
                page_number = 1;
                products = { ...products, products_data: defaultProducts }
                break;
            case false:
                dispatch({
                    type: "FETCH_PRODUCTS_COLLECTION",
                    payload: defaultProducts
                });
                break;
            default:
                break;
        }

        dispatch({
            type: "FETCH_PRODUCTS_COLLECTION_ERROR",
            payload: false
        });

        if (page_number !== false) {
            if (shop.data.store_type === "Woocommerce") {
                await WoocommerceApi(shop.data)
                    .get("products", {
                        category: collection_id,
                        per_page: per_page,
                        page: page_number,

                    })
                    .then((res) => {
                        let category_title = "";

                        if ((page_number > 1) && (products.products_data.category_title !== "")) {
                            category_title = products.products_data.category_title;
                        } else if (res.data) {
                            res.data.some((prod) => {
                                if (prod.categories && (prod.categories.length > 0)) {
                                    const category = prod.categories.find(c => c.id === parseInt(collection_id))
                                    if (category) {
                                        category_title = category.name;
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    return false;
                                }
                            });
                        }

                        if (res.data) {
                            const data = res.data.map((prod) => {
                                const price = (prod.sale_price !== "") ? prod.sale_price : (prod.price !== "") ? prod.price : 0;
                                return {
                                    id: prod.id,
                                    images: [{ src: prod?.images[0]?.src || null }],
                                    title: prod.name,
                                    vendor: "",
                                    meta_data: prod.meta_data || null,
                                    date_on_sale_to_gmt: prod.date_on_sale_to_gmt || null,
                                    date_on_sale_from_gmt: prod.date_on_sale_from_gmt || null,
                                    variants: [{
                                        id: prod.id,
                                        compareAtPrice: regularPrice(prod.regular_price, price),
                                        price: parseInt(price),
                                        priceV2: {
                                            currencyCode: extractCurrency(prod.price_html)
                                        }
                                    }],
                                    category: { id: prod?.categories[0]?.id || null, title: prod?.categories[0]?.name || "" }
                                }
                            });

                            defaultProducts = {
                                category_title,
                                total_pages: res.headers['x-wp-totalpages'] > page_number ? res.headers['x-wp-totalpages'] : 0,
                                products: [...products.products_data.products, ...data]
                            };
                        }

                    }).then(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION_ERROR",
                            payload: true
                        });
                    });
            } else {
                const shopifyApi = shopifyClient(shop.data);

                const args = { productsFirst: per_page, reverse: true };

                if (page_number === 1) {

                    await shopifyApi.collection.fetchWithProducts(collection_id, args).then((res) => {
                        if (res) {
                            defaultProducts = {
                                category_title: res.title,
                                total_pages: (res.products.length > 0) ? res.products[res.products.length - 1].hasNextPage ? 1 : 0 : 0,
                                products: res.products
                            }
                        }
                    }).then(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION_ERROR",
                            payload: true
                        });
                    });

                } else {

                    await shopifyApi.fetchNextPage(products.products_data.products).then((prod) => {
                        defaultProducts = {
                            category_title: products.products_data.category_title,
                            total_pages: prod.model[prod.model.length - 1].hasNextPage ? 1 : 0,
                            products: [...products.products_data.products, ...prod.model]
                        }
                    }).then(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_PRODUCTS_COLLECTION_ERROR",
                            payload: true
                        });
                    });

                }
            }
        }

        dispatch({
            type: "FETCHING_PRODUCTS_BY_COLLECTION",
            payload: false
        });

    }
};

//Fetch - single product
export const fetchProduct = (productId) => {
    return async function (dispatch, getState) {

        const shop = getState().shop;

        dispatch({
            type: "FETCHING_PRODUCT",
            payload: true
        });

        if (shop.data.store_type === "Woocommerce") {
            await WoocommerceApi(shop.data).get(`products/${productId}`).then((res) => {
                if (res.data) {
                    const price = (res.data.sale_price !== "") ? res.data.sale_price : (res.data.price !== "") ? res.data.price : 0;
                    const product = {
                        id: res.data.id,
                        attributes: res.data.attributes || [],
                        images: [{ src: res?.data?.images[0]?.src || null }],
                        title: res.data.name,
                        descriptionHtml: res.data.description,
                        meta_data: res?.data?.meta_data || null,
                        date_on_sale_to_gmt: res?.data?.date_on_sale_to_gmt || null,
                        date_on_sale_from_gmt: res?.data?.date_on_sale_from_gmt || null,
                        variants: [{
                            id: res.data.id,
                            compareAtPrice: regularPrice(res.data.regular_price, price),
                            price: parseInt(price),
                            priceV2: {
                                currencyCode: extractCurrency(res.data.price_html)
                            },
                            product: {
                                collections: [{
                                    id: res.data?.categories[0]?.id || null,
                                    title: res.data?.categories[0]?.name || ""
                                }]
                            }
                        }]
                    };

                    if (res.data.type && (res.data.type === "variable")) {
                        dispatch(fetchProductVariations(productId));
                        const compare_attr = {};
                        product.attributes.forEach((attr) => {
                            compare_attr[attr.name.toLowerCase()] = attr.options;
                        });
                        product.compare_attr = compare_attr;
                    } else {
                        dispatch({
                            type: "FETCH_PRODUCTS_VARIATIONS",
                            payload: {
                                variations: [],
                                on_sale: [],
                                compare_variations: null
                            }
                        })
                    }

                    dispatch({
                        type: "FETCH_PRODUCT",
                        payload: product
                    });
                }
            }).catch(() => {
                dispatch({
                    type: "FETCH_PRODUCT_ERROR",
                    payload: true
                });
            });
        } else {
            const shopifyApi = shopifyClient(shop.data);

            await shopifyApi.product.fetch(productId).then((res) => {
                dispatch({
                    type: "FETCH_PRODUCT",
                    payload: res
                });
            }).catch(() => {
                dispatch({
                    type: "FETCH_PRODUCT_ERROR",
                    payload: true
                });
            });
        }

        dispatch({
            type: "FETCHING_PRODUCT",
            payload: false
        });

    }
};

// Fetch - search products
export const fetchSearchProducts = (searchValue, page_number = 0) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            products_per_page = 10;
        let search = getState().search,
            defaultProducts = {
                total_pages: 0,
                products: []
            };

        dispatch({
            type: "FETCHING_SEARCH_PRODUCTS",
            payload: true
        });

        switch (page_number) {
            case 0:
                page_number = (shop.data.store_type === "Woocommerce") ? 1 : 0;
                search = { search_data: defaultProducts }
                break;
            case false:
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS",
                    payload: defaultProducts
                });
                break;
            default:
                break;
        }

        if (page_number !== false) {
            if (searchValue.length > 0) {
                if (shop.data.store_type === "Woocommerce") {
                    const args = {
                        "search": searchValue,
                        "per_page": products_per_page
                    };

                    if (page_number !== 0) {
                        args.page = page_number;
                    }

                    await WoocommerceApi(shop.data).get("products", args).then((res) => {
                        if (res.data) {
                            const data = res.data.map((prod) => {
                                const price = (prod.sale_price !== "") ? prod.sale_price : (prod.price !== "") ? prod.price : 0;
                                return {
                                    id: prod.id,
                                    images: [{ src: prod?.images[0]?.src || null }],
                                    title: prod.name,
                                    descriptionHtml: prod.description,
                                    meta_data: prod.meta_data || null,
                                    date_on_sale_to_gmt: prod?.date_on_sale_to_gmt || null,
                                    date_on_sale_from_gmt: prod?.date_on_sale_from_gmt || null,
                                    variants: [{
                                        id: prod.id,
                                        compareAtPrice: regularPrice(prod.regular_price, price),
                                        price: parseInt(price),
                                        priceV2: {
                                            currencyCode: extractCurrency(prod.price_html)
                                        },
                                        product: {
                                            collections: [{
                                                id: prod?.categories[0]?.id || null,
                                                title: prod?.categories[0]?.name || ""
                                            }]
                                        }
                                    }]
                                }
                            });

                            defaultProducts = {
                                total_pages: (res.headers['x-wp-totalpages'] > page_number) ? res.headers['x-wp-totalpages'] : 0,
                                products: [...search.search_data.products, ...data]
                            }
                        }
                    }).then(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        })
                    });
                } else {
                    const shopifyApi = shopifyClient(shop.data);

                    const args = { first: products_per_page, "query": "title:" + searchValue + "*" };

                    if (page_number !== 0) {
                        args.after = page_number;
                    }

                    const productQuery = shopifyApi.graphQLClient.query((root) => {
                        root.addConnection('products', { args }, (product) => {
                            product.add("id");
                            product.add("title");
                            product.add("description");
                            product.addConnection("images", { args: { first: 5 } }, (image) => {
                                image.add('src');
                            });
                            product.addConnection("variants", { args: { first: 5 } }, (variant) => {
                                variant.add("compareAtPrice");
                                variant.add("price");
                                variant.add("priceV2", (price) => {
                                    price.add("currencyCode");
                                });
                                variant.add("product", (product) => {
                                    product.add("handle");
                                    product.add("id");
                                    product.addConnection("collections", { args: { first: 5 } }, (collection) => {
                                        collection.add("title");
                                        collection.add("id");
                                        collection.add("handle");
                                    });
                                })
                            });
                        });
                    });

                    await shopifyApi.graphQLClient.send(productQuery).then((model, data) => {
                        defaultProducts = {
                            total_pages: model.data.products.pageInfo.hasNextPage
                                ? model.data.products.edges[model.data.products.edges.length - 1].cursor : 0,
                            products: [...search.search_data.products, ...model.model.products]
                        }

                    }).then(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    });
                }
            } else {
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS",
                    payload: defaultProducts
                });
            }
        }

        dispatch({
            type: "FETCHING_SEARCH_PRODUCTS",
            payload: false
        });

    }
}

// Fetch - search products in category
export const fetchSearchCategoryProducts = (searchValue, category_id, page_number = 0) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            products_per_page = 10;
        let search = getState().search,
            defaultProducts = {
                total_pages: 0,
                products: []
            };

        dispatch({
            type: "FETCHING_SEARCH_PRODUCTS",
            payload: true
        });

        switch (page_number) {
            case 0:
                page_number = (shop.data.store_type === "Woocommerce") ? 1 : 0;
                search = { search_data: defaultProducts }
                break;
            case false:
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS",
                    payload: defaultProducts
                });
                break;
            default:
                break;
        }

        if (page_number !== false) {
            if (searchValue.length > 0) {
                if (shop.data.store_type === "Woocommerce") {
                    const args = {
                        "search": searchValue,
                        "per_page": products_per_page,
                        "order_by": "popularity",
                        "category": category_id,
                    };

                    if (page_number !== 0) {
                        args.page = page_number;
                    }

                    await WoocommerceApi(shop.data).get("products", args).then((res) => {
                        if (res.data) {
                            const data = res.data.map((prod) => {
                                const price = (prod.sale_price !== "") ? prod.sale_price : (prod.price !== "") ? prod.price : 0;
                                return {
                                    id: prod.id,
                                    images: [{ src: prod?.images[0]?.src || null }],
                                    title: prod.name,
                                    descriptionHtml: prod.description,
                                    meta_data: prod.meta_data || null,
                                    date_on_sale_to_gmt: prod?.date_on_sale_to_gmt || null,
                                    date_on_sale_from_gmt: prod?.date_on_sale_from_gmt || null,
                                    variants: [{
                                        id: prod.id,
                                        compareAtPrice: regularPrice(prod.regular_price, price),
                                        price: parseInt(price),
                                        priceV2: {
                                            currencyCode: extractCurrency(prod.price_html)
                                        },
                                        product: {
                                            collections: [{
                                                id: prod?.categories[0]?.id || null,
                                                title: prod?.categories[0]?.name || ""
                                            }]
                                        }
                                    }]
                                }
                            });

                            defaultProducts = {
                                total_pages: (parseInt(res.headers['x-wp-totalpages']) > page_number) ? res.headers['x-wp-totalpages'] : 0,
                                products: [...search.search_data.products, ...data]
                            }
                        }
                    }).then(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        })
                    });
                } else {
                    // TODO: Waiting for query for Shopify
                    const shopifyApi = shopifyClient(shop.data);

                    const args = {
                        first: products_per_page,
                        "query": "title:" + searchValue + "*"
                    };

                    if (page_number !== 0) {
                        args.after = page_number;
                    }

                    const productQuery = shopifyApi.graphQLClient.query((root) => {
                        root.addConnection('products', { args }, (product) => {
                            product.add("id");
                            product.add("title");
                            product.add("description");
                            product.addConnection("images", { args: { first: 5 } }, (image) => {
                                image.add('src');
                            });
                            product.addConnection("variants", { args: { first: 5 } }, (variant) => {
                                variant.add("compareAtPrice");
                                variant.add("price");
                                variant.add("priceV2", (price) => {
                                    price.add("currencyCode");
                                });
                                variant.add("product", (product) => {
                                    product.add("handle");
                                    product.add("id");
                                    product.addConnection("collections", { args: { first: 5 } }, (collection) => {
                                        collection.add("title");
                                        collection.add("id");
                                        collection.add("handle");
                                    });
                                })
                            });
                        });
                    });

                    await shopifyApi.graphQLClient.send(productQuery).then((model, data) => {
                        defaultProducts = {
                            total_pages: model.data.products.pageInfo.hasNextPage
                                ? model.data.products.edges[model.data.products.edges.length - 1].cursor : 0,
                            products: [...search.search_data.products, ...model.model.products]
                        }

                    }).then(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    }).catch(() => {
                        dispatch({
                            type: "FETCH_SEARCH_PRODUCTS",
                            payload: defaultProducts
                        });
                    });
                }
            } else {
                dispatch({
                    type: "FETCH_SEARCH_PRODUCTS",
                    payload: defaultProducts
                });
            }
        }

        dispatch({
            type: "FETCHING_SEARCH_PRODUCTS",
            payload: false
        });

    }
}

//Fetch - fetch recommended products
export const fetchRecommendedProducts = (product_id = null, category_id = null) => {
    return async function (dispatch, getState) {

        const shop = getState().shop;

        dispatch({
            type: "FETCHING_RECOMMENDED_PRODUCTS",
            payload: true
        });

        if ((shop.data.store_type === "Woocommerce") && category_id) {
            await WoocommerceApi(shop.data)
                .get("products", {
                    category: category_id,
                    per_page: 10,

                })
                .then((res) => {

                    if (res.data) {
                        const data = res.data.map((prod) => {
                            const price = (prod.sale_price !== "") ? prod.sale_price : (prod.price !== "") ? prod.price : 0;
                            return {
                                id: prod.id,
                                images: [{ src: prod?.images[0]?.src || null }],
                                title: prod.name,
                                vendor: "",
                                meta_data: prod.meta_data || null,
                                date_on_sale_to_gmt: prod.date_on_sale_to_gmt || null,
                                date_on_sale_from_gmt: prod.date_on_sale_from_gmt || null,
                                variants: [{
                                    id: prod.id,
                                    compareAtPrice: prod.sale_price ? regularPrice(prod.regular_price, price) : null,
                                    price: parseInt(price),
                                    priceV2: {
                                        currencyCode: extractCurrency(prod.price_html)
                                    }
                                }],
                                category: { id: prod?.categories[0]?.id || null, title: prod?.categories[0]?.name || "" }
                            }
                        });

                        return { products: data };
                    } else {
                        return { products: [] };
                    }

                }).then((data) => {
                    dispatch({
                        type: "RECOMMENDED_PRODUCTS",
                        payload: data.products
                    });
                }).catch(() => {
                    dispatch({
                        type: "RECOMMENDED_PRODUCTS_ERROR",
                        payload: true
                    });
                });
        } else if (product_id) {
            const shopifyApi = shopifyClient(shop.data);

            await shopifyApi.product.fetchRecommendations(product_id).then((res) => {
                dispatch({
                    type: "RECOMMENDED_PRODUCTS",
                    payload: res
                });
            }).catch(() => {
                dispatch({
                    type: "RECOMMENDED_PRODUCTS_ERROR",
                    payload: true
                });
            });
        }

        dispatch({
            type: "FETCHING_RECOMMENDED_PRODUCTS",
            payload: false
        });

    }
};