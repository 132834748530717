import shopifyClient from "../../apis/shopifyApi";
import { subscribeToCheckout } from "../../apis/apiData";
import WooCommerceAPI from "./../../apis/woocommerceApi";
import analyticsEvents from "../../analyticsEvents";

//Create checkout
export const createCheckout = () => {
    return async function (dispatch, getState) {
        const shop = getState().shop;

        if (shop.data.store_type === "Woocommerce") {
            await WooCommerceAPI(shop.data).post("orders")
                .then((res) => {
                    sessionStorage.setItem(shop.data.id, JSON.stringify({ id: res.data.id, webUrl: "" }));
                    dispatch({
                        type: "CREATE_CHECKOUT",
                        payload: res.data
                    });
                    subscribeToCheckout(shop.data, res.data.id);
                    analyticsEvents.orderCreate(shop.data.id, res.data.id);
                })
                .catch(() => {
                    dispatch({
                        type: "CREATE_CHECKOUT",
                        payload: null
                    });
                });
        } else {
            const shopifyApi = shopifyClient(shop.data);

            await shopifyApi.checkout.create().then((res) => {
                sessionStorage.setItem(shop.data.id, JSON.stringify({ id: res.id, webUrl: res.webUrl }));
                dispatch({
                    type: "CREATE_CHECKOUT",
                    payload: res
                });
                subscribeToCheckout(shop.data, res.id);
                analyticsEvents.orderCreate(shop.data.id, res.id);
            }).catch(() => {
                dispatch({
                    type: "CREATE_CHECKOUT",
                    payload: null
                });
            });
        }
    }
};

//Fetch checkout
export const fetchCheckout = (checkoutID = null) => {
    return async (dispatch, getState) => {
        const checkout = getState().checkout,
            shop = getState().shop,
            cart = getState().cart;

        let line_Items = cart.lineItems || [];
        let hasCheckout = false;

        dispatch({
            type: "UPDATING_CART",
            payload: true
        });

        if (!checkoutID) {
            checkoutID = checkout?.data?.id || null;
        } else {
            hasCheckout = true;
        }

        if (checkoutID) {
            if (shop.data.store_type === "Woocommerce") {
                await WooCommerceAPI(shop.data).get(`orders/${checkoutID}`)
                    .then(async (res) => {
                        if (res.status === 200) {
                            if (res.data.line_items.length > 0) {
                                if (res.data.status === "on-hold") {
                                    line_Items = [];
                                    if (sessionStorage.getItem(shop.data.id)) {
                                        sessionStorage.removeItem(shop.data.id);
                                    }

                                    dispatch({
                                        type: "CREATE_CHECKOUT",
                                        payload: null
                                    });

                                } else {
                                    if (hasCheckout) {
                                        sessionStorage.setItem(shop.data.id, JSON.stringify({ id: res.data.id, webUrl: "" }));
                                        dispatch({
                                            type: "CREATE_CHECKOUT",
                                            payload: { id: checkoutID }
                                        });
                                    }
                                    await WooCommerceAPI(shop.data).get("products", {
                                        include: res.data.line_items.map((item) => item.product_id),
                                        per_page: res?.data?.line_items?.length || 10
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            line_Items = response.data.map((item) => {
                                                const product = res.data.line_items.filter(i => i.product_id === item.id) || [];
                                                const categoryMinId = Math.min(...item.categories.map(({ id }) => id));
                                                const category = item.categories.find(({ id }) => id === categoryMinId);
                                                return {
                                                    order_id: (product.length > 0) ? product[0].id : null,
                                                    id: item.id,
                                                    quantity: (product.length > 0) ? product[0].quantity : 0,
                                                    title: item.name,
                                                    meta_data: item?.meta_data || null,
                                                    variant: {
                                                        id: item.id,
                                                        compareAtPrice: item.regular_price,
                                                        priceV2: {
                                                            amount: item.price !== "" ? item.price : 0,
                                                            currencyCode: res.data.currency_symbol
                                                        },
                                                        image: { src: item.images[0].src },
                                                        product: {
                                                            collections: [{
                                                                id: category?.id || null,
                                                                title: category?.name || ""
                                                            }]
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    }).finally(() => {
                                        dispatch({
                                            type: "FETCH_CHECKOUT",
                                            payload: line_Items
                                        });
                                    });
                                }
                            } else {
                                dispatch({
                                    type: "FETCH_CHECKOUT",
                                    payload: []
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error.response.data);
                        if (error.response.status !== 404) {
                            dispatch({
                                type: "FETCH_CHECKOUT",
                                payload: line_Items
                            });
                        }
                    });
            } else {

                const shopifyApi = shopifyClient(shop.data);

                const args = {
                    id: checkoutID
                };

                const checkoutQuery = shopifyApi.graphQLClient.query((root) => {
                    root.add("node", { args }, (node) => {
                        node.addInlineFragmentOn("Checkout", (checkout) => {
                            checkout.add("id");
                            checkout.add("webUrl");
                            checkout.add("totalPrice");
                            checkout.add("createdAt");
                            checkout.add("completedAt");
                            checkout.addConnection("lineItems", { args: { first: 250 } }, (lineItems) => {
                                lineItems.add("id");
                                lineItems.add("quantity");
                                lineItems.add("title");
                                lineItems.add("variant", (variant) => {
                                    variant.add("id");
                                    variant.add("compareAtPriceV2", (compareAtPrice) => {
                                        compareAtPrice.add("amount");
                                    });
                                    variant.add("priceV2", (price) => {
                                        price.add("currencyCode");
                                        price.add("amount");
                                    });
                                    variant.add("image", (image) => {
                                        image.add("src");
                                    });
                                    variant.add("product", (product) => {
                                        product.add("id");
                                        product.addConnection("collections", { args: { first: 5 } }, (collections) => {
                                            collections.add("id");
                                            collections.add("title");
                                        });
                                    });
                                });
                            });
                        });
                    });
                });

                await shopifyApi.graphQLClient.send(checkoutQuery).then((model, data) => {
                    if (model.model.node) {
                        if (model.model.node.completedAt) {
                            line_Items = [];
                            if (sessionStorage.getItem(shop.data.id)) {
                                sessionStorage.removeItem(shop.data.id);
                            }
                            dispatch({
                                type: "CREATE_CHECKOUT",
                                payload: null
                            });
                        } else {
                            if (hasCheckout) {
                                sessionStorage.setItem(shop.data.id, JSON.stringify({ id: model.model.node.id, webUrl: model.model.node.webUrl }));
                                dispatch({
                                    type: "CREATE_CHECKOUT",
                                    payload: { id: checkoutID }
                                });
                            }
                            line_Items = model.model.node.lineItems.map((item) => {
                                return {
                                    order_id: null,
                                    id: item.id,
                                    quantity: item.quantity,
                                    title: item.title,
                                    variant: {
                                        id: item.variant.id,
                                        compareAtPrice: item.variant.compareAtPriceV2?.amount || null,
                                        priceV2: {
                                            amount: item.variant.priceV2.amount !== "" ? item.variant.priceV2.amount : 0,
                                            currencyCode: item.variant.priceV2.currencyCode
                                        },
                                        image: { src: item.variant.image.src },
                                        product: {
                                            id: item.variant.product.id,
                                            collections: [{
                                                id: item?.variant?.product?.collections[0]?.id || null,
                                                title: item?.variant?.product?.collections[0]?.title || ""
                                            }]
                                        }
                                    }
                                }
                            });
                        }
                    }

                })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        dispatch({
                            type: "ADD_ITEM_TO_CART",
                            payload: line_Items
                        });
                    });

            }
        }

        dispatch({
            type: "UPDATING_CART",
            payload: false
        });

    }
}