import React from "react";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";

import { DynamicPriceFontSize, onSaleTo, calcPercentage, removeUnnecessaryDecimals } from "../../../globalFunctions";
import analyticsEvents from "../../../analyticsEvents/index";

import noImg from "../assets/img/no_img.png";

import "react-loading-skeleton/dist/skeleton.css";
import "../assets/css/catalog.css";
import "./assets/css/top_offers.css";

class TopOffers extends React.Component {

    render() {
        const {
            product,
            loading,
            category,
            isOnTheCart,
            addRemoveItem,
            shop_custom_theme
        } = this.props,
            { custom_theme, shop_color, discount_price_color, reqular_price_color, shop_id } = shop_custom_theme,
            change_color = (custom_theme && shop_color) || false;
        const fromHome = new URLSearchParams(window.location.search).has('from');
        return (
            <div className="topOffer" key={product.id}>
                <div className="topOfferTitle" style={change_color ? { color: shop_color } : {}}>
                    {loading ? <Skeleton containerClassName="topOfer-skeleton" /> : category?.title}
                </div>
                <Link
                    className="productLink"
                    to={`/product/${product.id}/${category?.id}${fromHome ? '?from=home' : ''}`}
                    style={loading ? { pointerEvents: "none" } : null}
                    onClick={() => {
                        analyticsEvents.topProductDetails(shop_id, product.id, true);
                    }}
                >
                    <div className="productTitle">
                        {loading
                            ? (
                                <Skeleton containerClassName="title-skeleton" />
                            )
                            : (
                                <TextTruncate
                                    line={2}
                                    truncateText="..."
                                    text={product.title}
                                />
                            )
                        }
                    </div>
                    <div className="productImage">
                        {loading
                            ? (
                                <Skeleton
                                    height="215px"
                                    containerClassName="image-skeleton"
                                />
                            )
                            : (
                                <>
                                    <img
                                        className="topProductImage"
                                        src={product?.images[0]?.src ? (product.images[0].src) : noImg}
                                        alt="product_image"
                                    />
                                    {(product.date_on_sale_from_gmt || product.date_on_sale_to_gmt) && (
                                        onSaleTo(
                                            {
                                                from: product.date_on_sale_from_gmt,
                                                to: product.date_on_sale_to_gmt
                                            },
                                            shop_custom_theme
                                        )
                                    )}
                                </>
                            )
                        }
                    </div>
                </Link>
                <div className="priceCartContainer">
                    <div className="productPriceWrapper" style={{ width: loading ? "35%" : "auto" }}>
                        {loading
                            ? (
                                <Skeleton containerClassName="price-skeleton" />
                            )
                            : (
                                <div>
                                    {((product.variants[0].compareAtPrice != null) && (product.variants[0].compareAtPrice > product.variants[0].price)) ? (
                                        <div
                                            className="discountPercentage"
                                            style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                        >
                                            {calcPercentage(product.variants[0].compareAtPrice, product.variants[0].price)}
                                        </div>
                                    ) : (
                                        <div style={{ height: "30px" }}></div>
                                    )}
                                    <div
                                        className="productPrice"
                                        style={(custom_theme && discount_price_color) ? { backgroundColor: discount_price_color } : {}}
                                    >
                                        {DynamicPriceFontSize(
                                            80,
                                            removeUnnecessaryDecimals(product.variants[0].price),
                                            30
                                        )}
                                        <span className="currency">{product.variants[0].priceV2.currencyCode}</span>
                                    </div>
                                    {((product.variants[0].compareAtPrice != null) && (parseInt(product.variants[0].compareAtPrice) > parseInt(product.variants[0].price))) && (
                                        <div
                                            className="comparePrice"
                                            style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                        >
                                            {DynamicPriceFontSize(
                                                41,
                                                removeUnnecessaryDecimals(product.variants[0].compareAtPrice),
                                                11
                                            )}
                                            <span>{product.variants[0].priceV2.currencyCode}</span>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    </div>

                    {loading
                        ? (
                            <div className="toCart-skeleton-wrapper">
                                <Skeleton containerClassName="addToCart-skeleton" />
                            </div>
                        )
                        : (
                            <div
                                className={`cartButton ${isOnTheCart ? "added" : ""}`}
                                style={(change_color && isOnTheCart) ? { background: shop_color } : {}}
                            >
                                <button
                                    className="addCartButton"
                                    onClick={() => {
                                        if (isOnTheCart) {
                                            addRemoveItem("remove", isOnTheCart);
                                            analyticsEvents.removeProductFromCart(shop_id, product.id);
                                        } else {
                                            addRemoveItem("add", { product, quantity: 1 });
                                            analyticsEvents.addProductToCart(shop_id, 1, product.id);
                                        }
                                    }}
                                >
                                    <div
                                        className={isOnTheCart ? "checkmark_icon" : "plus_icon"}
                                        style={(change_color && !isOnTheCart) ? { backgroundColor: shop_color } : {}}
                                    ></div>
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>

        );
    }
}

export default withTranslation()(TopOffers);