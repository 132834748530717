import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import history from "../history";
import analyticsEvents from "../../analyticsEvents";

import { ReactComponent as ShoppingCart } from "./assets/img/shopping_cart.svg";

import "./assets/css/tabBar.css";


class TabBar extends React.Component {

    fromHome = false;

    componentDidMount() {
        const fromHome = new URLSearchParams(window.location.search);
        if (fromHome.has('from') && fromHome.get('from') === 'home') {
            this.fromHome = true;
        }
        setTimeout(() => {
            this.forceUpdate();
        }, 500);
    }

    goToPath = () => {
        if (sessionStorage.getItem("categoryId")) {
            history.push(`/category/${sessionStorage.getItem("categoryId")}${this.fromHome ? '?from=home' : ''}`);
        } else {
            history.push('/');
        }

    }

    render() {
        const categoryId = sessionStorage.getItem("categoryId");

        const cartItems = this.props.cart.lineItems ? this.props.cart.lineItems.length || 0 : 0,
            { activeTab, showShopOptions, t, shop, current_price, saved, currency } = this.props,
            { custom_theme, shop_color, category } = shop.data,
            change_color = (custom_theme && shop_color) || false;

        return (
            <div className="tabBarContainer">
                <div className="bgContainer">
                    {!this.fromHome ? null : (
                        (activeTab === "cart") ? (
                            <>
                                {(cartItems > 0) && (
                                    <div className="shop_ammount">
                                        <div className="total_wrapper">
                                            {t("total")}
                                            <span>{current_price} {currency}</span>
                                        </div>
                                        <div className="save_wrapper">
                                            {t("you_save")}
                                            <span style={change_color ? { color: shop_color } : {}}>
                                                {saved} {currency}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className="cartBg"
                                    style={shop_color ? { background: shop_color } : {}}
                                >
                                    <div className="cartIcon" onClick={showShopOptions}>
                                        <ShoppingCart />
                                        <span
                                            key={Date.now()}
                                            className="number_of_items"
                                            style={shop_color ? { borderColor: shop_color } : {}}
                                        >
                                            {(cartItems > 99) ? <>99<sup>+</sup></> : cartItems}
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                className="cartBg"
                                style={change_color ? { background: shop_color } : {}}
                            >
                                <button
                                    className="cartIcon"
                                    onClick={() => {
                                        analyticsEvents.shoppingListShow(shop.data.id);
                                        history.push(`/cart${this.fromHome ? '?from=home' : '?from=category'}`);
                                    }}
                                >
                                    <ShoppingCart />
                                    <span
                                        key={Date.now()}
                                        style={(custom_theme && shop_color) ? { borderColor: shop_color } : {}}
                                    >
                                        {cartItems > 99 ? <>99<sup>+</sup></> : cartItems}
                                    </span>
                                </button>
                            </div>
                        )
                    )}
                    <div className="iconsContainer" key={Date.now()}>
                        <div
                            className="icon_wrapper"
                            onClick={this.goToPath}
                        >
                            <div
                                className={`categoryIcon ${(activeTab === "home" || (activeTab === 'category' && categoryId)) ? "active" : ""}`}
                                style={(change_color && (activeTab === "home" || (activeTab === 'category' && categoryId))) ? { background: shop_color } : {}}
                            ></div>
                            <div
                                className={`label ${activeTab === "home" ? "active" : ""}`}
                                style={(change_color && (activeTab === "home" || (activeTab === 'category' && categoryId))) ? { color: shop_color } : {}}
                            >
                                {(category && (category?.id === 3)) ? t("menu_menu") : t("menu_promotions")}
                            </div>
                        </div>
                        {!this.fromHome ? (
                            <div
                                className="icon_wrapper search"
                                onClick={() => {
                                    if (activeTab === 'cart') {
                                        showShopOptions();
                                    } else {
                                        analyticsEvents.shoppingListShow(shop.data.id);
                                        history.push(`/cart${this.fromHome ? '?from=home' : '?from=category'}`);
                                    }
                                }}
                            >
                                <div className="cartIcon">
                                    <div className={`cart_icon ${activeTab === "cart" ? "active" : ""}`}
                                        style={(change_color && (activeTab === "cart")) ? { background: shop_color } : {}} />
                                    <span
                                        key={Date.now()}
                                        style={(custom_theme && shop_color && activeTab === "cart") ? { borderColor: shop_color } : {}}
                                    >
                                        {cartItems > 99 ? <>99<sup>+</sup></> : cartItems}
                                    </span>
                                </div>
                                <div
                                    className={`label ${activeTab === "cart" ? "active" : ""}`}
                                    style={(change_color && (activeTab === "cart")) ? { color: shop_color } : {}}
                                >
                                    {t("menu_cart")}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="icon_wrapper search"
                                onClick={() => {
                                    analyticsEvents.productSearch(shop.data.id);
                                    history.push(`/search${this.fromHome ? '?from=home' : ''}`);
                                }}
                            >
                                <div
                                    className={`searchIcon ${activeTab === "search" ? "active" : ""}`}
                                    style={(change_color && (activeTab === "search")) ? { background: shop_color } : {}}
                                ></div>
                                <div
                                    className={`label ${activeTab === "search" ? "active" : ""}`}
                                    style={(change_color && (activeTab === "search")) ? { color: shop_color } : {}}
                                >
                                    {t("menu_search")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        shop: state.shop
    };
};

export default connect(mapStateToProps)(withTranslation()(TabBar));