import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";

import Category from "../catalog/categories/index";
import TabBar from "../tabBar/tabBar";

import { fetchCollections } from "../../actions/categories/index";
import history from "../history";

import "./assets/css/categories.css";

class Categories extends React.Component {

    constructor() {
        super();
        this.state = {
            page_number: 1
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.is_component) {
            this.props.fetchCollections();
        }
    }

    componentWillUnmount() {
        this.props.fetchCollections(false);
    }

    renderCategories() {
        const SkeletonData = [1, 2, 3, 4, 5, 6].map((i) => {
            return {
                id: i,
                skeleton: true,
                products: [i]
            }
        }),
            { collections } = this.props;
        const categories = collections.LOADING ? SkeletonData : collections?.collections_data?.collections || [];
        if (categories.length > 0) {
            return categories.map((coll) => {
                if (coll.products && (coll.products.length > 0)) {
                    return (
                        <Category
                            key={coll.id}
                            collection={coll}
                            catalogVendor={this.props.vendor}
                            loading={coll.skeleton || false}
                            fromPage='home'
                        />
                    )
                } else {
                    return null;
                }
            });
        } else {
            return null
        }
    }

    fetchMoreCollections = () => {
        const { shop, collections } = this.props;
        this.setState((state) => ({
            page_number: (shop.data.store_type === "Woocommerce") ? state.page_number + 1 : collections.collections_data.total_pages
        }), () => {
            this.props.fetchCollections(this.state.page_number);
        });
    }

    render() {
        const { collections, shop, t, is_component } = this.props,
            { shop_color } = shop.data,
            change_color = (shop.data.custom_theme && shop_color) || false;
        return (
            <>
                <div className={`categories_container ${is_component ? "is_component" : ""}`}>
                    {!is_component && (
                        <>
                            <div className="categories_header">
                                <div
                                    className="go_back"
                                    onClick={() => history.goBack()}
                                >
                                    <div
                                        className="angle_left_icon"
                                        style={change_color ? { background: shop_color } : {}}
                                    ></div>
                                    <span
                                        style={change_color ? { color: shop_color } : {}}
                                    >
                                        {t("btn_back")}
                                    </span>
                                </div>
                            </div>
                            <h2 className="categoryTitle">
                                {t("categories")}
                            </h2>
                        </>
                    )}
                    <InfiniteScroll
                        dataLength={collections?.collections_data?.collections?.length || 0}
                        next={this.fetchMoreCollections}
                        hasMore={(collections.collections_data.total_pages !== 0)}
                        loader={<div className="categories_loader"></div>}
                        scrollThreshold={1}
                        className="categoriesWrapper"
                    >
                        {this.renderCategories()}
                    </InfiniteScroll>
                </div>
                <TabBar />
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        collections: state.collections,
        shop: state.shop
    };
};

export default connect(mapStateToProps, {
    fetchCollections,
})(withRouter(withTranslation()(Categories)));